import flatten from 'flat'
import { MD5 } from 'crypto-js'

export default function getHash( variable ) {
  const flat = flatten( variable )
  const sorted = Object.keys( flat )
    .map( key => ( { __key: key, __val: flat[ key ] } ) )
    .sort( field => field.__key + field.__key )
  const stringified = JSON.stringify( sorted )
  const hash = MD5( stringified ).toString()

  return hash
}
