import React, { createContext, useState } from 'react'

const FilterContext = createContext()

const defaultState = {
  model: {},
  hydrated: {},
  steps: []
}

const defaultScope = {
  state: defaultState,
  update: () => {},
  reset: () => {}
}

function Filter( props ) {
  const [ state, setState ] = useState( defaultState )

  function update( u ) {
    setState( { ...u } )
  }

  function reset() {
    setState( defaultState )
  }

  return (
    <FilterContext.Provider
      value={ {
        state,
        update,
        reset
      } }
    >
      { props.children }
    </FilterContext.Provider>
  )
}

const withFilter = ( ComponentAlias ) => {
  return props => (
    <FilterContext.Consumer>
      { context => <ComponentAlias
        { ...props }
        withFilter={ context || defaultScope }
      /> }
    </FilterContext.Consumer>
  )
}

export {
  withFilter,
  FilterContext
}

export default Filter
