import { useParams, useNavigate } from "react-router-dom"

function withParams( Component ) {
  return props => <Component { ...props } params={ useParams() } />
}

function withNavigate( Component ) {
  return props => <Component { ...props } navigate={ useNavigate() } />
}

export {
  withParams,
  withNavigate
}
