import Comatize from '../Comatize'

import {
  FiChevronsLeft,
  FiChevronLeft,
  FiChevronsRight,
  FiChevronRight
} from 'react-icons/fi'

export default function Pager( props ) {
  const { data, updateParams } = props

  function first() {
    updateParams( {
      offset: 0,
      limit: data.limit
    } )
  }

  function last() {
    const { total, limit } = data
    const rest = total % limit

    let offset = total - rest

    if ( rest === 0 )
      offset = total - limit

    updateParams( {
      offset,
      limit: data.limit
    } )
  }

  function prev( event ) {
    const { limit, offset } = data

    updateParams( {
      offset: offset - limit,
      limit
    } )
  }

  function next( event ) {
    const { limit, offset } = data

    updateParams( {
      offset: offset + limit,
      limit
    } )
  }

  function isLast() {
    const { total, limit, offset } = data

    return offset + limit >= total
  }

  function isFirst() {
    const { offset } = data

    return offset === 0
  }

  function updateLimit( event ) {
    const { value: limit } = event.target

    updateParams( {
      offset: 0,
      limit
    } )
  }

  return (
    <>
      <div className="pager">
        <span className="info">
          <Comatize>
            { data.offset + 1 }
          </Comatize>

          &nbsp;
          -
          &nbsp;

          <Comatize>
            { data.offset + data.limit }
          </Comatize>

          &nbsp;
          de
          &nbsp;

          <Comatize>
            { data.total }
          </Comatize>
        </span>

        <div className="ipp">
          <span>
            Tama&ntilde;o p&aacute;gina
          </span>

          <select
            value={ data.limit }
            onChange={ updateLimit }
          >
            <option>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
          </select>
        </div>

        <div className="actions">
          <button
            onClick={ first }
            disabled={ isFirst() }
          >
            <FiChevronsLeft />
          </button>

          <button
            onClick={ prev }
            disabled={ isFirst() }
          >
            <FiChevronLeft />
          </button>

          <button
            onClick={ next }
            disabled={ isLast() }
          >
            <FiChevronRight />
          </button>

          <button
            onClick={ last }
            disabled={ isLast() }
          >
            <FiChevronsRight />
          </button>
        </div>
      </div>
    </>
  )
}
