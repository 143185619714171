import React, { createContext, useState } from 'react'

const AlertContext = createContext()

function Alert( props ) {
  const [ alertList, setAlertList ] = useState( [] )

  function add( alertItem ) {
    setAlertList( [ ...alertList, alertItem ] )
  }

  function remove( alertItem ) {
    setAlertList( [ ...alertList.filter( item => item.id !== alertItem.id ) ] )
  }

  return (
    <AlertContext.Provider
      value={ {
        alertList,
        add,
        remove
      } }
    >
      { props.children }
    </AlertContext.Provider>
  )
}

const withAlert = ( ComponentAlias ) => {
  return props => (
    <AlertContext.Consumer>
      { context => <ComponentAlias { ...props } withAlert={ context } /> }
    </AlertContext.Consumer>
  )
}

export {
  withAlert,
  AlertContext
}

export default Alert
