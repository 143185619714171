import http from '../../http'
import { withColumns } from '../../context/Columns'
import { withFilter } from '../../context/Filter'
import DownloadButton from '../../components/DownloadButton'
import buildIcon from '../../helpers/buildIcon'

function ExportTable( props ) {
  const { action, withColumns } = props
  const { endpoint = '/api/report/export' } = action

  const Icon = buildIcon( action.icon )

  const {
    model = {},
    steps = []
  } = props.withFilter.state

  function downloadTable( cb ) {
    const cols = withColumns.columns
      .filter( ( col ) => col.view )

    http
      .request( {
        url: endpoint,
        method: 'POST',
        responseType: 'blob',
        data: [
          ...steps,
          { __columns: cols }
        ],
        silence: true
      } )
      .then( cb )
  }

  return (
    <>
      <DownloadButton
        title={ action.title }
        uri={ action.endpoint }
        prefix={ action.prefix }
        useFilter={ action.useFilter }
        action={ downloadTable }
      >
        <Icon />
      </DownloadButton>
    </>
  )
}

export default withColumns( withFilter( ExportTable ) )
