import axios from 'axios'

let requestCount = 0

function addLoader() {
  const loaderWrap = document.createElement( 'div' )
  loaderWrap.setAttribute( 'class', 'loader-wrap' )
  const loader = document.createElement( 'div' )
  loader.setAttribute( 'class', 'ripple' )
  const ci = document.createElement( 'div' )
  const cii = document.createElement( 'div' )
  loader.appendChild( ci )
  loader.appendChild( cii )
  loaderWrap.appendChild( loader )
  document.body.appendChild( loaderWrap )
}

function removeLoader() {
  const a = document.querySelector( '.loader-wrap' )
  a.remove()
}

function isLoader() {
  return document.querySelector( '.loader-wrap' )
}

function add() {
  requestCount++

  if ( requestCount > 0 && ! isLoader() )
    addLoader()
}

function remove() {
  requestCount--

  if ( requestCount === 0 && isLoader() )
    removeLoader()
}

const http = axios.create( {
  baseUrl: process.env.REACT_APP_BASE_URL || ''
} )

http.interceptors.request.use( config => {
  let token = localStorage.getItem( 'token' )
  if ( /^\/dataHub/.test( config.url ) )
    token = localStorage.getItem( 'dataHubToken' )

  config.headers[ 'Authorization' ] = token

  if ( ! config.silence )
    add()

  return config
} )

http.interceptors.response.use(
  response => {
    remove()
    return response
  },
  error => {
    remove()
    if ( error.config.rawError )
      throw error

    if ( error.response.status === 401 ) {
      if ( /^\/dataHub/.test( error.config.url ) ) {
        localStorage.removeItem( 'dataHubToken' )
        return window.location.replace( '/a-pi' )
      }

      localStorage.clear()
      return window.location.replace( '/auth/login' )
    }

    throw error
  }
)

export default http
