import { faker } from '@faker-js/faker'

function pie() {
  return [
    { platform: faker.random.alpha( 7 ), count: faker.random.number( 20000 ) },
    { platform: faker.random.alpha( 7 ), count: faker.random.number( 20000 ) },
    { platform: faker.random.alpha( 7 ), count: faker.random.number( 20000 ) },
    { platform: faker.random.alpha( 7 ), count: faker.random.number( 20000 ) },
    { platform: faker.random.alpha( 7 ), count: faker.random.number( 20000 ) }
  ]
}

export default pie
