import React, { useState } from 'react'

import {
  FiEyeOff,
  FiEye
} from 'react-icons/fi'

export default function FadeCheck( props ) {
  const { initialValue = false, update } = props
  const [ isSelected, setIsSelected ] = useState( initialValue )

  function change( event ) {
    update( ! isSelected )
    setIsSelected( ! isSelected )
  }

  return (
    <div
      className={
        'fade-check' + (
          isSelected ? ' checked' : ''
        )
      }
      onClick={ change }
    >
      { isSelected ? <FiEyeOff /> : <FiEye /> }
    </div>
  )
}
