export default function MonthPicker( props ) {
  const {
    name,
    model,
    placeholder
  } = props

  function updateModel( event ) {
    const [ value, setValue ] = model
    setValue( {
      ...value,
      [ name ]: event.target.value
    } )
  }

  return (
    <>
      <label>{ placeholder }</label>
      <input
        type="date"
        name={ name }
        onChange={ updateModel }
        size="1"
        required
      />
    </>
  )
}
