import React, { Component } from 'react'
import http from '../../../http'
import FadeCheck from '../../../components/FadeCheck'
import buildIcon from '../../../helpers/buildIcon'

import {
  FiDownloadCloud
} from 'react-icons/fi'

class ExportButtons extends Component {
  state = {
    exportButtons: []
  }

  componentDidMount() {
    http
      .get( '/api/collection/exportButtons/all' )
      .then( response => {
        this.setState( ( state, props ) => ( {
          ...state,
          exportButtons: response.data
        } ) )
      } )
  }

  update( path, checked ) {
    const { collection, update: _update } = this.props

    const index = collection.indexOf( path )
    if ( checked ) {
      if ( index === -1 )
        collection.push( path )
    } else {
      if ( index !== -1 )
        collection.splice( index, 1 )
    }

    _update( collection )
  }

  render() {
    const it = this
    const { collection } = this.props

    const exportButtons = this.state.exportButtons
      .map( ( el, i ) => {
        const { identity, title, icon } = el
        const Icon = buildIcon( icon )
        const checked = collection.indexOf( identity ) !== -1

        return (
          <div className="one hs centered card padding relative" key={ i }>
            <Icon className="text x5" />

            <label className="text centered">{ title }</label>

            <FadeCheck
              initialValue={ checked }
              update={ ( checked ) => it.update( identity, checked ) }
            />
          </div>
        )
      } )

    return (
      <>
        <div className="title">
          <FiDownloadCloud />
          <h1>exportables</h1>
        </div>

        <div className="vs padding">
          { exportButtons }
        </div>
      </>
    )
  }
}

export default ExportButtons
