import { FiExternalLink } from 'react-icons/fi'

export default function LINK( value, label ) {
  if ( ! value ) return null

  return <a
    href={ value }
    target="_blank"
    rel="noreferrer"
  >
    { label } <FiExternalLink />
  </a>
}
