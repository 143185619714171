import React, { useState } from 'react'
import { withFilter } from '../context/Filter'
import moment from 'moment'
import http from '../http'

import {
  FiLoader
} from 'react-icons/fi'

function getExt( mime ) {
  switch ( mime ) {
    case 'text/csv':
      return '.csv'
    case 'application/zip':
      return '.zip'
    default:
      return ''
  }
}

function DownloadButton( props ) {
  const {
    title,
    loadingTitle = 'construyendo archivo',
    endpoint,
    useFilter = 0,
    prefix,
    action
  } = props
  const [ isLoading, setIsLoading ] = useState( false )

  function save( response ) {
    setIsLoading( false )
    const mime = response.headers[ 'content-type' ].split( /\s+/ )[0].replace( /;$/, '' )
    const ext = getExt( mime )

    const downloadUrl = window.URL.createObjectURL( new Blob( [ response.data ] ) )
    const filename = `${ prefix }${ moment().format( 'YYYY-MM-DD' ) }${ ext }`
    const link = document.createElement( 'a' )
    link.href = downloadUrl
    link.setAttribute( 'download', filename )
    link.click()
  }

  function download() {
    if ( isLoading )
      return

    setIsLoading( true )

    if ( action )
      return action( save )

    const options = {
      url: endpoint,
      method: 'POST',
      responseType: 'blob',
      silence: true
    }

    if ( useFilter )
      options[ 'data' ] = props.withFilter.state.steps

    http
      .request( options )
      .then( save )
  
  }

  return (
    <button
      title={ isLoading ? loadingTitle : title }
      onClick={ download }
    >
      { isLoading ? (
        <FiLoader className="spin" />
      ) : props.children }
    </button>
  )
}

export default withFilter( DownloadButton )
