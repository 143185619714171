import React, { useState } from 'react'
import Filter from '../../context/Filter'
import { withProfile } from '../../context/Profile'

import ExportButtons from './_ExportButtons'
import Cards from './Cards'
import Filters from './_Filters'

import {
  FiFilter
} from 'react-icons/fi'

function Board( props ) {
  const { profile } = props.withProfile
  const [ isFilterOpened, setIsFilterOpened ] = useState( false )

  function toggleFilter() {
    setIsFilterOpened( ! isFilterOpened )
  }

  if ( ! profile._id )
    return null

  return (
    <Filter>
      <main>
        <header>
          <h1>Detalle de las acciones</h1>

          <div className="actions">
            <ExportButtons />

            <button onClick={ toggleFilter }>
              <FiFilter />
            </button>
          </div>
        </header>

        <section className="padding">

          <Cards />

        </section>
      </main>

      { isFilterOpened ? <Filters
        close={ toggleFilter }
      /> : null }

    </Filter>
  )
}

export default withProfile( Board )
