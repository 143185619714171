import GeneralReport from '../components/GeneralReport'
import EditRow from '../components/EditRow'
import Confirm from '../components/Confirm'

const modals = {
  GeneralReport,
  EditRow,
  Confirm
}

export default modals
