import React, { useState, useEffect } from 'react'
import http from '../http'
import moment from 'moment'

import { FiX } from 'react-icons/fi'

function EditableField( props ) {
  const {
    identity,
    initialValue,
    options: sourceOptions,
    endpoint,
    refresh = () => {}
  } = props

  const [ editable, setEditable ] = useState( false )
  const [ options, setOptions ] = useState( [] )

  useEffect( () => {
    setOptions( [ ...( sourceOptions
      .map( o => o.label )
    ) ] )
  }, [ sourceOptions ] )

  function toggleEditable() {
    setEditable( ! editable )
  }

  function updateField( event ) {
    const { value } = event.target

    const item = sourceOptions.find( o => o.label === value )
    if ( ! item ) return

    let { value: data } = item
    if ( data.indexOf( '__NOW__' ) !== -1 )
      data = data.replaceAll( '"__NOW__"', JSON.stringify( moment().toISOString() ) )

    data = { ...JSON.parse( data ), _id: identity }

    http
      .put( endpoint, data )
      .then( response => {
        toggleEditable()
        refresh()
      } )
  }

  let input
  switch ( props.type ) {
    case 'select':
      input = <select
          value={ initialValue }
          onChange={ updateField }
        >
        { options.map( ( op, i ) => (
          <option
            key={ i }
          >
            { op }
          </option>
        ) ) }
      </select>
      break
    default:
      input = 'unsupported input'
  }

  return (
    <>
      { editable ? <>
        { input }
        <button onClick={ toggleEditable }>
          <FiX />
        </button>
      </> : <label onClick={ toggleEditable }>
        { initialValue }
      </label> }
    </>
  )
}

export default EditableField
