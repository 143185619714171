import Comatize from '../Comatize'
import {
  Sector
} from 'recharts'

export default function CustomActive( props ) {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
  } = props

  const offset = 21
  const p = <Comatize>{ ( percent * 100 ).toFixed( 2 ) }</Comatize>
  const words = payload.label.split( /\s+/ )

  let y = cy - words.length * offset * 0.25
  const texts = words
    .map( ( word, index ) => {

      return (
        <text
          key={ `w-${ word }` }
          x={ cx }
          y={ y + offset * index }
          fill='var( --color )'
          stroke='transparent'
          textAnchor="middle"
        >
          { word }
        </text>
      )
    } )

  return(
    <g>
      { texts }

      <text
        x={ cx }
        y={ y + offset * words.length }
        fill='var( --color )'
        stroke='transparent'
        textAnchor="middle"
      >
        { p }%
      </text>

      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />

      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={innerRadius - 10}
        outerRadius={innerRadius - 6}
        fill={fill}
      />
    </g>
  )
}
