import React, { createContext, useState, useEffect } from 'react'
import http from '../http'

const CatalogContext = createContext()

function Catalog( props ) {
  const [ catalog, setCatalog ] = useState( {
    cards: [],
    filters: [],
    exportButtons: [],
    aside: []
  } )

  useEffect( () => {
    if ( catalog.cards.length !== 0 ) return

    async function fetchData() {
      const [
        cards,
        filters,
        exportButtons,
        aside
      ] = [
        await http.get( '/api/collection/cards/all' ),
        await http.get( '/api/collection/filters/all' ),
        await http.get( '/api/collection/exportButtons/all' ),
        await http.get( '/api/collection/aside/all' )
      ].map( response => response.data )

      setCatalog( {
        cards,
        filters,
        exportButtons,
        aside
      } )
    }

    fetchData()
  }, [ catalog ] )

  if ( catalog.cards.length === 0 )
    return null

  return (
    <CatalogContext.Provider
      value={ {
        catalog
      } }
    >
      { props.children }
    </CatalogContext.Provider>
  )
}

const withCatalog = ( ComponentAlias ) => {
  return props => (
    <CatalogContext.Consumer>
      { context => <ComponentAlias { ...props } withCatalog={ context } /> }
    </CatalogContext.Consumer>
  )
}

export {
  withCatalog,
  CatalogContext
}

export default Catalog
