import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import http from '../../http'

export default function Token( props ) {
  const navigate = useNavigate()
  const params = useParams()

  useEffect( () => {
    http
      .get( `/api/auth/${ params.ownerId }` )
      .then( response => {
        localStorage.setItem( 'token', response.data.token )
        localStorage.setItem( 'username', response.data.username )
        navigate( '/board' )
      } )
      .catch( err => navigate( '/auth/login' ) )
  } )

  return (
    <label>
      { params.ownerId }
    </label>
  )
}
