import { withAlert } from '../context/Alert'
import Toast from './Toast'

function AlertList( props ) {
  const { alertList, remove } = props.withAlert

  function deleteIt( item ) {
    remove( item )
  }

  return (
    <>
      { alertList.length !== 0 ? (
        <div className="toast-list">
          { alertList.map( ( el, index ) => (
            <Toast
              key={ el.id }
              type={ el.type }
              icon={ el.icon }
              title={ el.title }
              message={ el.message }
              timeout={ el.timeout }
              onClick={ () => deleteIt( el ) }
            />
          ) ) }
        </div>
      ) : null }
    </>
  )
}

export default withAlert( AlertList )
