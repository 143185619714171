import React, { useState } from 'react'
import Fetch from '../../../components/Fetch'
import T, { Pager } from '../../../components/Table'
import { withColumns } from '../../../context/Columns'

function Table( props ) {
  const { card, withColumns } = props
  const [ query, setQuery ] = useState( { ...card.query } )
  const selected = useState( [] )

  return (
    <div className="hs one">
      <Fetch
        type="table"
        endpoint={ card.endpoint }
        params={ card.params }
        query={ query }
        useFilter={ card.useFilter }
      >
        { ( data, update ) => (
          <div className="hs card table">
            <T
              data={ data.page }
              columns={ withColumns.columns }
              selected={ selected }
            />

            <Pager
              data={ data }
              updateParams={ setQuery }
            />
          </div>
        ) }
      </Fetch>
    </div>
  )
}

export default withColumns( Table )
