import React, { createContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import http from '../http'

const ProfileContext = createContext()

function Profile( props ) {
  const [ profile, setProfile ] = useState( {
    _id: null,
    ownerId: null,
    cards: [],
    filters: [],
    exportButtons: []
  } )
  const navigate = useNavigate()

  const { endpoint } = props

  useEffect( () => {
    if ( ! endpoint ) return

    if ( profile._id ) return

    http
      .get( endpoint )
      .then( response => {
        setProfile( response.data )
      } )
      .catch( err => {
        if ( err.response.status === 404 ) {
          localStorage.clear()
          navigate( '/auth/login' )
        }
      } )
  } )

  function updateProfile( value ) {
    setProfile( value )
  }

  // prevent render
  if ( ! profile._id ) return null

  return (
    <ProfileContext.Provider
      value={ {
        profile,
        updateProfile
      } }
    >
      { props.children }
    </ProfileContext.Provider>
  )
}

const withProfile = ( ComponentAlias ) => {
  return props => (
    <ProfileContext.Consumer>
      { context => <ComponentAlias { ...props } withProfile={ context } /> }
    </ProfileContext.Consumer>
  )
}

export {
  withProfile,
  ProfileContext
}

export default Profile
