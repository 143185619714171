import Timeout from './Timeout'
import buildIcon from '../helpers/buildIcon'

export default function Toast( props ) {
  const {
    type = 'alert',
    title,
    icon = 'FiAlertTriangle',
    message,
    timeout = 7,
    onClick = () => {}
  } = props

  const classes = [ 'toast', type ]

  const Icon = buildIcon( icon )

  return (
    <Timeout timeout={ timeout } onClick={ onClick }>
      <div className={ classes.join( ' ' ) }>
        <header>
          { icon ? (
            <div className="icon">
              <Icon />
            </div>
          ) : null }

          <h1>{ title }</h1>
        </header>
        <section>
          { message }
        </section>
      </div>
    </Timeout>
  )
}
