import React, { Component, Fragment, useState, useEffect } from 'react'
import http from '../../../http'
import buildIcon from '../../../helpers/buildIcon'
import { faker } from '@faker-js/faker'

import FadeCheck from '../../../components/FadeCheck'
import Pie from '../../../components/Pie'
import Line from '../../../components/Line'
import Table from '../../../components/Table'

import {
  table as fakeTable,
  line as fakeLine,
  pie as fakePie
} from '../fakeData'

import {
  FiHexagon
} from 'react-icons/fi'

function FakeTable( props ) {
  const selected = useState( [] )

  return (
    <Table
      data={ fakeTable }
      selected={ selected }
      columns={ [
        {
          name: 'Titulo',
          view: true,
          span: 'two'
        },
        {
          name: 'Autor Bibliografico',
          as: 'Autor Bibliográfico',
          view: true,
          span: 'two'
        },
        {
          name: 'Enlace archivo',
          view: true,
          transform: 'TRANSFORM_LINK',
          params: [ 'enlace' ]
        },
        {
          name: 'Estado',
          view: true
        },
        {
          name: 'Fecha notificado',
          view: true,
          transform: 'TRANSFORM_DATE',
          params: [ 'YYYY-MM-DD' ]
        },
        {
          name: 'Fecha eliminado',
          view: true,
          transform: 'TRANSFORM_DATE',
          params: [ 'YYYY-MM-DD' ]
        }
      ] }
    />
  )
}

class Cards extends Component {

  state = {
    cards: [],
    columns: []
  }

  componentDidMount() {
    http
      .get( '/api/collection/cards/all' )
      .then( response => {
        this.setState( ( state, props ) => ( {
          ...state,
          cards: response.data
        } ) )
      } )
  }

  buildCard( card, index, it, parent ) {
    if ( typeof card.type !== 'string' )
      return null

    const { collection } = it.props
    const identity = parent ? `${ parent }.${ card.identity }` : card.identity
    const checked = collection.indexOf( identity ) !== -1

    switch ( card.type ) {
      case 'group':
        return <div className="vs wrap" key={ index }>
          { card.childs.map( ( el, i ) => it.buildCard( el, i, it, identity ) ) }
        </div>
      case 'number':
        const Icon = buildIcon( card.icon )

        return <div className="hs one card padding relative" key={ index }>

          <div className="vs one">
            <div className="zero">
              <Icon
                className="text x5"
                style={ {
                  color: card.color
                } }
              />
            </div>

            <div className="hs one">
              <h1 className="text p121 uppercase">
                { card.title }
              </h1>

              <span className="text p149 bold">
                { faker.random.number( 200000 ) }
              </span>
            </div>
          </div>

          { card.items && card.items.length !== 0 ? (
            <div className="vs">
              { card.items.map( ( el, i ) => {
                return (
                  <div className="hs one centered" key={ i }>
                    <h3 className="text p77 uppercase">{ el.title }</h3>
                    <span className="text p107 bold">{ faker.random.number( 50000 ) }</span>
                  </div>
                )
              } ) }
            </div>
          ) : null }

          <FadeCheck
            initialValue={ checked }
            update={ ( checked ) => it.update( identity, checked ) }
          />
        </div>
      case 'pie':
        return <div className="one hs centered card graph padding relative" key={ index }>
          <h1 className="text p121 uppercase">{ card.title }</h1>

          <Pie
            data={ [ ...fakePie() ] }
            labelKey="platform"
            valueKey="count"
          />

          <FadeCheck
            initialValue={ checked }
            update={ ( checked ) => it.update( identity, checked ) }
          />
        </div>
      case 'area':
        return <div
          className="vs card graph relative"
          style={ { width: '100%' } }
          key={ index }
        >
          <Line
            data={ [ ...fakeLine ] }
          />

          <FadeCheck
            initialValue={ checked }
            update={ ( checked ) => it.update( identity, checked ) }
          />
        </div>
      case 'table':
        return <div
          className="hs one relative card table"
          key={ index }
        >

          <FakeTable />

          <FadeCheck
            initialValue={ checked }
            update={ ( checked ) => it.update( identity, checked ) }
          />
        </div>
      default:
        return null
    }
  }

  update( path, checked ) {
    const { collection, update: _update } = this.props

    const index = collection.indexOf( path )
    if ( checked ) {
      if ( index === -1 )
        collection.push( path )
    } else {
      if ( index !== -1 )
        collection.splice( index, 1 )
    }

    _update( collection )
  }

  render() {
    const cards = this.state.cards
      .map( ( el, i ) => {
        const Icon = buildIcon( el.icon )

        return (
          <Fragment key={ i }>
            <div className="subtitle">
              <Icon />
              <h1>{ el.title }</h1>
            </div>

            { this.buildCard( el, i, this ) }
          </Fragment>
        )
      } )

    return (
      <>
        <div className="title">
          <FiHexagon />
          <h1>targetas</h1>
        </div>

        <div className="hs padding">
          { cards } 
        </div>
      </>
    )

  }
}

export default Cards
