import Comatize from '../Comatize'

export default function CustomLabel( props ) {
  const {
    index,
    cx,
    cy,
    fill,
    stroke,
    value: payload,
    total,
    length,
  } = props

  const offset = 21

  let y = cy - offset * length * 0.5
  y += offset * 0.7
  y += offset * index

  const x = cx * 2 + 7

  const percent = payload.value * 100 / total

  return (
    <g>
      <rect
        x={ x }
        y={ y - 14 }
        width={ 14 }
        height={ 14 }
        fill={ fill }
        stroke= { stroke }
      />
      <text
        className="pie-label"
        x={ x + 21 }
        y={ y }
        fill='var( --color )'
        stroke='transparent'
      >
        { payload.label }
        &nbsp;
        <Comatize>{ percent.toFixed( 2 ) }</Comatize>%
      </text>
    </g>
  )
}
