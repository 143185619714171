import { withProfile } from '../context/Profile'

function Comatize( props ) {
  const { children: value } = props
  const { coma = ',' } = props.withProfile.profile
  const dot = coma === ',' ? '.' : ','
  const localComa = Number( 10000 )
    .toLocaleString()
    .substr( 2, 1 )

  function toLocale( n ) {
    let ts = [ coma, coma ]
    let ds = [ dot, dot ]
    if ( coma !== localComa ) {
      ts = [ localComa, coma ]
      ds = [ coma, dot ]
    }

    const decimals = n % 1
    const integers = n - decimals

    let thousands = integers
    let rest = 0
    if ( integers < 10000 ) {
      thousands = Math.floor( integers / 1000 )
      rest = integers - 1000 * thousands
    }

    let left = thousands ? thousands.toLocaleString().replaceAll( ts[ 0 ], ts[ 1 ] ) : ''

    left += integers < 10000 ? ts[ 1 ] + ( '000' + rest ).slice( -3 ) : ''

    const re = new RegExp( `^${ ts[ 1 ] === '.' ? '\\.' : ',' }0*` )
    left = left.replace( re, '' )

    const right = decimals ? decimals.toLocaleString().replaceAll( ds[ 0 ], ds[ 1 ] ).replace( /^0/, '' ) : ''

    return ( integers > 0 ? left : '0' ) + right
  }

  return (
    <>
      { toLocale( Number( value ) ) }
    </>
  )
}

export default withProfile( Comatize )
