import TRANSFORM_JOIN from './_join'
import TRANSFORM_DOMAIN from './_domain'
import TRANSFORM_LINK from './_link'
import TRANSFORM_DATE from './_date'
import TRANSFORM_STRINGIFY from './_stringify'
import TRANSFORM_PARSE from './_parse'
import TRANSFORM_CURRENCY from './_currency'

const transformations = {
  TRANSFORM_JOIN,
  TRANSFORM_DOMAIN,
  TRANSFORM_LINK,
  TRANSFORM_DATE,
  TRANSFORM_STRINGIFY,
  TRANSFORM_PARSE,
  TRANSFORM_CURRENCY
}

export default transformations
