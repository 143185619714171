import React, { createContext, useState, useEffect } from 'react'
import http from '../http'

const ColumnsContext = createContext()

function Columns( props ) {
  const [ columns, setColumns ] = useState( [] )
  const {
    endpoint = '/api/table/cols',
    exclude = []
  } = props

  // useEffect( (

  useEffect( () => {
    http
      .get( endpoint )
      .then( response => {
        setColumns( response.data.filter( c => exclude.indexOf( c.name ) === -1 ) )
      } )
  }, [ endpoint ] )

  function toggle( index ) {
    const beforeCols = [ ...columns ]
    beforeCols[ index ].view = ! beforeCols[ index ].view

    setColumns( beforeCols )
  }

  // prevent render
  if ( columns.length === 0 )
    return null

  return (
    <ColumnsContext.Provider
      value={ {
        columns,
        toggle
      } }
    >
      { props.children }
    </ColumnsContext.Provider>
  )
}

const withColumns = ( ComponentAlias ) => {
  return props => (
    <ColumnsContext.Consumer>
      { context => <ComponentAlias { ...props } withColumns={ context } /> }
    </ColumnsContext.Consumer>
  )
}

export {
  withColumns,
  ColumnsContext
}

export default Columns
