import buildCard from './utils/buildCard'

export default function Group( props ) {
  const { items } = props

  if ( ! items || ( items && items.length === 0 ) )
    return null

  return (
    <div className="vs wrap">
      { items.map( buildCard ) }
    </div>
  )
}
