import React, { useEffect } from 'react'
import { useNavigate, Outlet } from 'react-router-dom'
import Catalog from './context/Catalog'
import Profile from './context/Profile'
import Aside from './components/Aside'
import http from './http'

function App() {
  const navigate = useNavigate()

  useEffect( () => {
    const token = localStorage.getItem( 'token' )

    if ( ! token )
      navigate( '/auth/login' )

    http
      .post( '/api/auth/ping', { token } )
      .catch( err => navigate( '/auth/login' ) )

  } )

  return (
    <Catalog>
      <Profile endpoint="/api/user/profile">
        <Aside />
        <Outlet />
      </Profile>
    </Catalog>
  );
}

export default App;
