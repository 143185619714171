import React, { useState, useRef } from 'react'
import http from '../http'
import MonthPicker from './MonthPicker'

import {
  FiX,
  FiCheck
} from 'react-icons/fi'

export default function GeneralReport( props ) {
  const {
    close = () => {},
    endpoint,
    method = 'POST'
  } = props

  const model = useState( {
    from: '',
    to: ''
  } )

  const submitElem = useRef( null )

  function getReport() {
    submitElem.current.click()
  }

  function save( response ) {
    const downloadUrl = window.URL.createObjectURL( new Blob( [ response.data ] ) )
    const filename = response.headers[ 'content-disposition' ].match( /.*filename="(.*)"/ )[1]
    const link = document.createElement( 'a' )
    link.href = downloadUrl
    link.setAttribute( 'download', filename )
    link.click()
    close()
  }

  function submit( event ) {
    event.preventDefault()

    const value = model[ 0 ]

    let bodyKey = 'params'
    if ( method === 'POST' )
      bodyKey = 'data'

    http
      .request( {
        url: endpoint,
        method,
        responseType: 'blob',
        [ bodyKey ]: value
      } )
      .then( save )
  }

  return (
    <div className="modal x50">
      <main>
        <header>
          <h1>descargar informe</h1>

          <div className="actions">
            <button onClick={ getReport }>
              <FiCheck />
            </button>

            <button onClick={ close }>
              <FiX />
            </button>
          </div>
        </header>

        <section>
          <form onSubmit={ submit }>
            <div className="row one-two">
              <div className="form-group">
                <MonthPicker
                  name="from"
                  model={ model }
                  placeholder="fecha inicial"
                  required
                />
              </div>

              <div className="form-group">
                <MonthPicker
                  name="to"
                  model={ model }
                  placeholder="fecha final"
                  required
                />
              </div>
            </div>

            <button type="submit" className="hidden" ref={ submitElem }></button>
          </form>
        </section>
      </main>
    </div>
  )
}
