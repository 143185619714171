import React, { useState, useEffect } from 'react'
import { withAlert } from '../../context/Alert'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'
import http from '../../http'
import {
  FiUser,
  FiLock
} from 'react-icons/fi'

function Login( props ) {
  const { add } = props.withAlert
  const [ credentials, setCredentials ] = useState( {
    username: '',
    password: ''
  } )
  const navigate = useNavigate()

  useEffect( () => {
    const token = localStorage.getItem( 'token' )
    if ( token )
      navigate( '/board' )
  } )

  function login( event ) {
    event.preventDefault()

    http( {
      url: '/api/auth/login',
      method: 'POST',
      data: credentials,
      rawError: true,
      silence: true
    } )
      .then( response => {
        localStorage.setItem( 'token', response.data.token )
        navigate( '/board' )
      } )
      .catch( err => {
        const { status } = err.response
        let notification = {
          id: uuid(),
          timeout: 7
        }

        switch ( status ) {
          case 404:
            notification = {
              ...notification,
              type: 'error',
              icon: 'FiUserX',
              title: '404',
              message: 'Usuario no encontrado'
            }
            break
          case 401:
            notification = {
              ...notification,
              type: 'error',
              icon: 'FiLock',
              title: '401',
              message: 'Contraseña incorrecta'
            }
            break
          default:
            console.warn( 'unhandled error' )
        }
        add( notification )
      } )
  }

  return (
    <main className="hs middle">
      <div className="login">
        <section>
          <div className="logo w-300x"></div>
          <form onSubmit={ login } className="hs w-300x">
            <div className="vs composed-input">
              <div className="icon">
                <FiUser />
              </div>

              <input
                type="text"
                id="username"
                name="username"
                value={ credentials.username }
                onChange={ ( e ) => setCredentials( { ...credentials, username: e.target.value } ) }
                required
                placeholder="usuario"
                size="1"
                className="one"
              />
            </div>

            <div className="vs composed-input">
              <div className="icon">
                <FiLock />
              </div>

              <input
                type="password"
                id="password"
                name="password"
                value={ credentials.password }
                onChange={ ( e ) => setCredentials( { ...credentials, password: e.target.value } ) }
                required
                placeholder="contraseña"
                size="1"
                className="one"
              />
            </div>

            <div className="vs composed-input">
              <button
                type="submit"
                className="centered full"
              >
                Entrar
              </button>
            </div>
          </form>
        </section>
      </div>
    </main>
  )
}

export default withAlert( Login )
