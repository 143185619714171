import Empty from './Empty'

import {
  AreaChart as Chart,
  Area,
  XAxis,
  YAxis,
  Label,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

export default function Line( props ) {
  const {
    data,
    lines = [
      { name: 'verificado', color: '#04a777' },
      { name: 'notificado', color: '#fcba04' },
      { name: 'eliminado', color: '#e4572e' },
      { name: 'rechazado', color: '#8e44ad' }
    ]
  } = props

  return (
    <div style={ {
      width: '99%',
      height: 400
    } }>
      { data.length !== 0 ? (
        <ResponsiveContainer>
          <Chart
            data={ data }
            margin={ {
              top: 20,
              bottom: 40,
              left: 10,
              right: 10
            } }
          >
            <CartesianGrid
              stroke="white"
              strokeDasharray="5 5"
              vertical={ false }
            />

            <XAxis
              dataKey="name"
              tick={ { fill: 'var( --color )', angle: -45, textAnchor: 'end', style: { fontSize: '10px' } } }
            />

            <YAxis
              tick={ { fill: 'var( --color )', angle: -45 } }
            >
              <Label
                value="Total enlaces"
                angle={ -90 } position="insideLeft"
                fill="var( --color )"
              />
            </YAxis>

            { Object
              .keys( data[ 0 ] )
              .filter( k => k !== 'name' )
              .map( ( k ) => {
                const { color } = lines.find( i => i.name === k ) || { color: 'red' }

                return <Area
                  key={ k }
                  type="monotone"
                  dataKey={ k }
                  stroke={ color }
                  fill={ color }
                  strokeWidth={ 2 }
                  isAnimationActive={ false }
                />
              } )
            }

            <Tooltip className="line-tooltip" />

            <Legend verticalAlign="top" />
          </Chart>
        </ResponsiveContainer>
      ) : <Empty /> }
    </div>
  )
}
