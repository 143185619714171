import React, { useState } from 'react'
import { withColumns } from '../../context/Columns'
import buildIcon from '../../helpers/buildIcon'

import {
  FaCheckSquare,
  FaRegSquare
} from 'react-icons/fa'

import {
  FiX
} from 'react-icons/fi'

function _Selector( props ) {
  const { columns, toggle } = props.withColumns

  const cols = columns
    .map( ( col, index ) => {

      return (
        <li
          key={ col.name }
          onClick={ () => toggle( index ) }
        >
          <button>
            { col.view ? (
              <FaCheckSquare />
            ) : (
              <FaRegSquare />
            ) }
          </button>
          <span>{ col.as || col.name }</span>
        </li>
      )
    } )

  return (
    <div className="modal">
      <main>
        <header>
          <h1>columnas</h1>
          <div className="actions">
            <button onClick={ props.close }>
              <FiX />
            </button>
          </div>
        </header>

        <section>
          <ul className="base pointer">
            { cols }
          </ul>
        </section>
      </main>
    </div>
  )
}

const Selector = withColumns( _Selector )

export default function ColumnsSelector( props ) {
  const { action } = props
  const [ isOpen, setIsOpen ] = useState( false )

  function toggle() {
    setIsOpen( ! isOpen )
  }

  const Icon = buildIcon( action.icon )

  return (
    <>
      <button title={ action.title } onClick={ toggle }>
        <Icon />
      </button>

      { isOpen ? <Selector close={ toggle } /> : null }
    </>
  )
}
