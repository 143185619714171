import Fetch from '../../../components/Fetch'
import Table from './_Table'

export default function ApiCollections( props ) {
  return (
    <main>
      <header>
        <h1>api</h1>
      </header>

      <section className="padding">
        <Fetch
          type="table"
          endpoint="/api/schema"
          method="GET"
        >
          { ( data, update ) => {
            if ( data.length === 0 )
              return null

            return data.map( ( schema, index ) => <Table key={ index } schema={ schema } /> )
          } }
        </Fetch>
      </section>
    </main>
  )
}
