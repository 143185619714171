import { stringify } from 'yaml'
import Highlight from 'react-highlight'
import 'highlight.js/styles/docco.css'

export default function STRINGIFY( value ) {
  if ( ! value ) return null

  let code = stringify( value )
  code = code.replaceAll( ' ', "\u00a0" )

  return <Highlight language="yaml">
    { code }
  </Highlight>
}
