import React, { useState, useRef, useEffect } from 'react'
import http from '../http'
import { withProfile } from '../context/Profile'

function Autocomplete( props ) {
  const [ options, setOptions ] = useState( [] )
  const [ index, setIndex ] = useState( -1 )
  const [ localValue, setLocaValue ] = useState( props.value )
  const [ isActive, setIsActive ] = useState( false )
  const inputElem = useRef( null )

  useEffect( () => {
    fetch()
  }, [ localValue ] )

  function fetch() {
    // prevent fetch
    if ( ! isActive ) return

    if ( ! localValue || localValue === '' ) {
      setValue( '', true )
      return clear()
    }

    const params = JSON.parse (
      JSON.stringify( props.__external )
        .replace( '"VALUE"', `"${ localValue }"` )
    )

    let body = [
      { __external: params }
    ]

    http( {
      url: props.endpoint,
      method: 'POST',
      params: { key: props.k },
      data: body,
      silence: true
    } )
      .then( res => {
        setIndex( -1 )
        setOptions( res.data )
      } )
  }

  function navigate( event ) {
    if ( [ 13, 38, 40 ].indexOf( event.keyCode ) !== -1 )
      event.preventDefault()

    switch ( event.keyCode ) {
      case 40: // down
        if ( index < options.length - 1 )
          setIndex( index + 1 )
        else
          setIndex( 0 )
        break
      case 38: // up
        if ( index > 0 )
          setIndex( index -1 )
        else
          setIndex( options.length - 1 )
        break
      case 13: // enter
        const option = options[ index ]

        if ( option )
          setValue( option )

        clear()
        break
    }
  }

  function blur( event ) {
    const target = event.nativeEvent.explicitOriginalTarget

    let wrapper = target.parentElement.parentElement
    if ( target.nodeName !== 'LI' )
      wrapper = target.parentElement.parentElement.parentElement

    if ( wrapper.className === 'options' )
      return

    clear()
  }

  function clear( event ) {
    setOptions( [] )
    setIndex( -1 )
  }

  function setValue( value, enabled = false ) {
    setLocaValue( value )

    props.onSelect( { target: {
      id: props.id,
      name: props.name,
      value: value
    } } )
    setIsActive( enabled )
    clear()
  }

  return (
    <div className="autocomplete">
      <input
        ref={ inputElem }
        type="text"
        name={ props.name }
        value={ localValue }
        size="1"
        placeholder={ props.placeholder }
        onKeyDown={ navigate }
        onChange={ ( event ) => setLocaValue( event.target.value ) }
        onBlur={ blur }
        onFocus={ () => setIsActive( true ) }
      />

      <div className="options">
        <ul>
          { options.map( ( el, i ) => (
            <li
              className={ i === index ? 'selected' : '' }
              onMouseEnter={ () => setIndex( i ) }
              onClick={ () => setValue( el ) }
            >
              { el }
            </li>
          ) ) }
        </ul>
      </div>
    </div>
  )
}

export default withProfile( Autocomplete )
