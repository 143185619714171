import Fetch from '../../../components/Fetch'
import PieChart from '../../../components/Pie'

export default function Pie( props ) {
  const { card } = props

  return (
    <div className="one hs centered card graph paddig">
      <h1 className="text p121 uppercase">
        { card.title }
      </h1>

      <Fetch
        type="pie"
        endpoint={ card.endpoint }
        params={ card.params }
        useFilter={ card.useFilter }
      >
        { ( data ) => ( <PieChart
          data={ data instanceof Array ? data : [ data ] }
          labelKey={ card.labelKey }
          valueKey={ card.valueKey }
        /> ) }
      </Fetch>
    </div>
  )
}
