import React, { Component } from 'react'
import http from '../../../http'
import FadeCheck from '../../../components/FadeCheck'

import {
  FiFilter
} from 'react-icons/fi'

class Filters extends Component {
  state = {
    filters: []
  }

  constructor() {
    super()
    this.buildFormControls = this.buildFormControls.bind( this )
  }

  componentDidMount() {
    http
      .get( '/api/collection/filters/all' )
      .then( response => {
        this.setState( ( state, props ) => ( {
          ...state,
          filters: response.data
        } ) )
      } )
  }

  buildFormControls( controls, it ) {
    const { collection } = it.props

    return controls
      .sort( ( a, b ) => a.order - b.order )
      .map( ( control, i ) => {
        const { identity, label } = control
        const checked = collection.indexOf( identity ) !== -1

        return <div className="hs one relative" key={ i }>
          <label>{ label }</label>
          <input
            placeholder={ label }
            size="1"
          />

          <FadeCheck
            initialValue={ checked }
            update={ ( checked ) => it.update( identity, checked ) }
          />
        </div>
      } )
  }

  update( path, checked ) {
    const { collection, update: _update } = this.props

    const index = collection.indexOf( path )
    if ( checked ) {
      if ( index === -1 )
        collection.push( path )
    } else {
      if ( index !== -1 )
        collection.splice( index, 1 )
    }

    _update( collection )
  }

  render () {
    const filters = this.state.filters
      .map( ( el, i ) => (
        <div
          className="vs one"
          key={ i }
        >
          { this.buildFormControls( el.row, this ) }
        </div>
      ) )

    return (
      <>
        <div className="title">
          <FiFilter />
          <h1>filtros</h1>
        </div>

        <div className="hs padding">
          { filters }
        </div>
      </>
    )
  }
}

export default Filters
