export default function serialize( target ) {
  if ( ! target )
    return {}

  return Object.keys( target )
    .map( key => {
      let value = target[ key ]

      if ( value instanceof Set )
        value = Array.from( value )

      return { [ key ]: value }
    } )
    .filter( field => {
      const key = Object.keys( field ).at( 0 )
      const value = field[ key ]

      if ( value instanceof Array && value.length === 0 )
        return false

      return true
    } )
    .reduce( ( acc, cur ) => {
      return { ...acc, ...cur }
    }, {} )
}
