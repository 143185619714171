import React, { useState, useEffect } from 'react'
import { withParams, withNavigate } from '../../../components/withRouter'
import http from '../../../http'
import Cards from './_Cards'
import Filters from './_Filters'
import ExportButtons from './_ExportButtons'
import Aside from './_Aside'
import serialize from '../../../helpers/serialize'

// hash
import flatten from 'flat'
import { MD5 } from 'crypto-js'

import {
  FiX,
  FiSave
} from 'react-icons/fi'

function Edit( props ) {
  const { identity } = props.params

  const [ profile, setProfile ] = useState( {
    _id: null,
    username: null,
    cards: [],
    filters: [],
    exportButtons: [],
    aside: []
  } )

  const [ permissions, setPermissions ] = useState( {
    cards: [],
    filters: [],
    exportButtons: [],
    aside: []
  } )

  const [ pristine, setPristine ] = useState( true )

  useEffect( () => {
    const { cards, filters, exportButtons, aside } = profile
    const a = { cards, filters, exportButtons, aside }
    const b = permissions

    const ha = hash( a )
    const hb = hash( b )

    setPristine( ha === hb )
  }, [ permissions, profile ] )

  useEffect( () => {
    if ( profile._id ) return

    http
      .get( `/api/user/${ identity }/profile` )
      .then( ( response ) => {
        setProfile( response.data )

        setPermissions( {
          cards: [ ...response.data.cards ],
          filters: [ ...response.data.filters ],
          exportButtons: [ ...response.data.exportButtons ],
          aside: [ ...response.data.aside ]
        } )
      } )
      .catch( ( err ) => {
        close()
      } )
  } )

  function hash( variable ) {
    const flatVariable = flatten( variable )
    const sorted = Object.keys( flatVariable )
      .map( key => flatVariable[ key ] )
      .sort()
    const stringified = JSON.stringify( sorted )

    return MD5( stringified ).toString()
  }

  function close() {
    props.navigate( '/permissions' )
  }

  function save() {
    const model = serialize( permissions )
    const { _id } = profile

    http
      .put( `/api/user/${ _id }`, model )
      .then( ( response ) => {
        setProfile( response.data )
      } )
  }

  function setAside( value ) {
    setPermissions( { ...permissions, aside: value } )
  }

  function setCards( value ) {
    setPermissions( { ...permissions, cards: value } )
  }

  function setFilters( value ) {
    setPermissions( { ...permissions, filters: value } )
  }

  function setExportButtons( value ) {
    setPermissions( { ...permissions, exportButtons: value } )
  }

  return (
    <>
      { profile._id ? (
        <main>
          <header>
            <h1>Permisos</h1>

            <h2 className="text color-accent right zero padding-x">{ identity }</h2>

            <button
              className={ ! pristine ? 'i-am-here' : '' }
              title="guardar"
              onClick={ save }
            >
              <FiSave />
            </button>

            <button
              title="cancelar"
              onClick={ close }
            >
              <FiX />
            </button>
          </header>

          <section className="padding">
            <Aside
              collection={ permissions.aside }
              update={ setAside }
            />

            <Cards
              collection={ permissions.cards }
              update={ setCards }
            />

            <Filters
              collection={ permissions.filters }
              update={ setFilters }
            />

            <ExportButtons
              collection={ permissions.exportButtons }
              update={ setExportButtons }
            />
          </section>
        </main>
      ) : null }
    </>
  )
}

export default withParams( withNavigate( Edit ) )
