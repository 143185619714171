import React, { useState, useRef, useEffect } from 'react'
import http from '../http'
import Fetch from '../components/Fetch'
import { withColumns } from '../context/Columns'
import Select from '../components/Select'
import serialize from '../helpers/serialize'
import flatten from 'flat'
import set from 'set-value'
import moment from 'moment'

import {
  FiCheck,
  FiX
} from 'react-icons/fi'

import {
  AiOutlineClear
} from 'react-icons/ai'

function EditRow( props ) {
  const { close, row = {}, params, refresh } = props
  const [ columns, setColumns ] = useState( props.withColumns.columns )
  const [ model, setModel ] = useState( {} )
  const formElem = useRef( null )

  useEffect( () => {
    const mappedModel = props.withColumns.columns
      .reduce( ( acc, cur ) => {
        if ( cur.transform === 'TRANSFORM_DATE' ) {
          let date = ''
          if ( row[ cur.name ] )
            date = moment( row[ cur.name ] ).format( 'YYYY-MM-DD' )

          return { ...acc, [ cur.name ]: date }
        }

        return { ...acc, [ cur.name ]: row[ cur.name ] }
      }, {} )

    setModel( mappedModel )
  }, [ props.withColumns.columns ] )

  function submit() {
    const url = `/api/collection/${ params.schema }/update`
    const parsedModel = props.withColumns.columns
      .reduce( ( acc, cur ) => {
        const value = model[ cur.name ]

        if ( cur.transform === 'TRANSFORM_DATE' ) {
          let date = undefined
          if ( value )
            date = moment( value ).toISOString()

          return {
            ...acc,
            [ cur.name ]: date
          }
        }

        if ( [ 'integer', 'number' ].indexOf( cur.type ) !== - 1 ) {
          const value = Number( model[ cur.name ] )

          return {
            ...acc,
            [ cur.name ]: value
          }
        }

        return {
          ...acc,
          [ cur.name ]: value
        }
      }, {} )

    http
      .put( url, parsedModel )
      .then( response => {
        refresh()
        close()
      } )
  }

  function handleChange( event ) {
    const { name, value } = event.target
    setModel( { ...model, [ name ]: value } )
  }

  function buildField( field ) {
    if ( field.enum ) {
      return <div
        key={ field.name }
        className="form-group"
      >
        <label>
          { field.label || field.as || field.name }
        </label>

        <Select
          name={ field.name }
          value={ model[ field.name ] }
          options={ field.enum }
          onChange={ handleChange }
          multiple={ false }
          placeholder={ field.placeholder || field.label || field.as || field.name }
        />
      </div>
    }

    if ( field.__select ) {
      return <div
        key={ field.name }
        className="form-group"
      >
        <label>
          { field.label || field.as || field.name }
        </label>


        <Fetch
          endpoint={ field.__select.endpoint }
          params={ field.__select.params }
        >
          { ( data ) => (
            <Select
              name={ field.name }
              value={ model[ field.name ] }
              multiple={ false }
              options={ data || [] }
              onChange={ handleChange }
              placeholder={ field.placeholder || field.label || field.as || field.name }
            />
          ) }
        </Fetch>
      </div>
    }

    let type = 'text'

    if ( field.type === 'string' && field.format === 'date-time' )
      type = 'date'

    if ( field.type === 'integer' )
      type = 'number'

    return <div
      key={ field.name }
      className="form-group"
    >
      <label>
        { field.label || field.as || field.name }
      </label>

      <input
        id={ field.name }
        type={ type }
        name={ field.name }
        value={ model[ field.name ] }
        size="1"
        onChange={ handleChange }
        placeholder={ field.placeholder || field.label || field.as || field.name }
        disabled={ field.readonly ? true : false }
      />
    </div>
  }

  const fields = columns
    .filter( el => ! el.hidden )
    .map( ( el, i ) => {
      return buildField( el )
    } )

  return (
    <div className="modal x50">
      <main>
        <header>
          <h1>Editar</h1>

          <div className="actions">
            <button title="aplicar filtros" onClick={ submit }>
              <FiCheck />
            </button>

            { /*
            <button title="eliminar filtros" onClick={ clear }>
              <AiOutlineClear />
            </button>
              */ }

            <button title="cancelar" onClick={ close }>
              <FiX />
            </button>
          </div>
        </header>

        <section>
          <form ref={ formElem } className="filters">
            { fields }
          </form>
        </section>
      </main>
    </div>
  )
}

export default withColumns( EditRow )
