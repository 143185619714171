import buildIcon from '../helpers/buildIcon'

function Confirm( props ) {
  const {
    icon,
    title = 'confirm',
    message = 'do you want to perform this action?',
    notice = 'this action can not be undone.',
    buttons = [
      {
        icon: 'FiCheck',
        label: 'yes',
        type: 'yes',
        action: () => { console.log( 'yes' ) }
      },
      {
        icon: 'FiX',
        label: 'no',
        type: 'no',
        action: () => { console.log( 'no' ) }
      }
    ]
  } = props

  const MIcon = buildIcon( icon )

  return (
    <div className="modal x50">
      <main>
        <section className="balert padding">
          <div className="vs">
            <div className="icon">
              <MIcon />
            </div>

            <div className="message">
              <b>{ message }</b>
              <i>{ notice }</i>
            </div>
          </div>

          <div className="buttons">
            { buttons.map( b => {
              const Icon = buildIcon( b.icon )

              return (
                <button
                  key={ b.label }
                  className={ b.type }
                  onClick={ ( e ) => b.action( props ) }
                >
                  <Icon />

                  <label>{ b.label }</label>
                </button>
              )
            } ) }
          </div>
        </section>
      </main>
    </div>
  )
}

export default Confirm
