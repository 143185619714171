export default function Empty( props ) {

  return (
    <div className="empty">
      <p>
        no hay resultados
      </p>
    </div>
  )
} 
