import * as fi from 'react-icons/fi'
import * as bs from 'react-icons/bs'
import * as hi from 'react-icons/hi'
import * as si from 'react-icons/si'
import * as md from 'react-icons/md'
import * as ai from 'react-icons/ai'

function buildIcon( icon ) {
  if ( ! icon )
    return <></>

  switch ( icon.slice( 0, 2 ) ) {
    case 'Fi':
      return fi[ icon ]
    case 'Bs':
      return bs[ icon ]
    case 'Hi':
      return hi[ icon ]
    case 'Si':
      return si[ icon ]
    case 'Md':
      return md[ icon ]
    case 'Ai':
      return ai[ icon ]
    default:
      return <>unimported icon set { icon }</>
  }
}

export default buildIcon
