export default function DOMAIN( value ) {
  if ( ! value ) return null

  const x = value.match( /((f|ht)tps?:\/\/)(.*)\/?$/ )

  if ( ! x ) return value

  return x[3]
    .replace( /^www\./, '' )
    .replace( /\/$/, '' )
}
