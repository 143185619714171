const table = [
  {
    "_id": "6220709fd4b32d69dbdb7a58",
    "IdObraInternoCEDRO": "11450201",
    "Identificador": "978-84-204-3328-8",
    "Titulo": "Flores Sobre El Infierno",
    "Nombre fichero localizado": "Flores sobre el infierno en Español - Gratis - Mega Epubs",
    "Autor Bibliografico": "Tuti Ilaria ",
    "Traductor Bibliografico": "",
    "Editor Bibliografico": "Alfaguara",
    "Tipo de Obra": "Libro",
    "Fecha localizado": "2021-01-23T03:33:12.674Z",
    "Fecha notificado": "2021-07-21T18:15:17.951Z",
    "Fecha eliminado": "2021-08-23T23:53:16.456Z",
    "Dominio archivo": "https://megaepubs.com/",
    "Enlace archivo": "https://megaepubs.com/flores-sobre-el-infierno-en-espanol-gratis/",
    "Estado": "Eliminado",
    "idTitular": [ 2000582 ],
    "plataforma": "Search engine",
    "Tipo de pagina": "Página enlace"
  },
  {
    "_id": "6220709fd4b32d69dbdb7d79",
    "IdObraInternoCEDRO": "1768032",
    "Identificador": "978-84-9908-024-6",
    "Titulo": "Mal De Escuela",
    "Nombre fichero localizado": "Mal de escuela autor Daniel Pennac pdf español gratis",
    "Autor Bibliografico": "Pennac Daniel ",
    "Traductor Bibliografico": "Manuel Sarrat Crespo ",
    "Editor Bibliografico": "Debolsillo",
    "Tipo de Obra": "Libro",
    "Fecha localizado": "2021-01-23T10:05:17.110Z",
    "Fecha notificado": "2021-11-03T05:22:25.154Z",
    "Fecha eliminado": "2021-12-18T16:10:39.325Z",
    "Dominio archivo": "http://797798.eschelshoff.de/",
    "Enlace archivo": "http://797798.eschelshoff.de/",
    "Estado": "Eliminado",
    "idTitular": [ 2000582, 1002217 ],
    "plataforma": "Search engine",
    "Tipo de pagina": "Página enlace"
  }
]

export default table
