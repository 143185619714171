import React, { useState, useEffect } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { withProfile } from '../context/Profile'
import { withCatalog } from '../context/Catalog'
import buildIcon from '../helpers/buildIcon'

import { FiChevronsRight, FiChevronsLeft, FiPower } from 'react-icons/fi'

function Aside( props ) {
  const { toggleAt = 1100 } = props
  const [ isMinified, setIsMinified ] = useState( false )
  const { aside } = props.withProfile.profile
  const { aside: asideCatalog } = props.withCatalog.catalog
  const navigate = useNavigate()

  useEffect( () => {
    function handleResize() {
      const width = document.documentElement.clientWidth

      if ( width <= toggleAt )
        return setIsMinified( true )

      setIsMinified( false )
    }

    window.addEventListener( 'resize', handleResize )
  } )

  function logout() {
    localStorage.clear()
    navigate( '/auth/login' )
  }

  const menu = aside
    .map( item => {
      return asideCatalog.find( i => i.identity === item )
    } )
    .map( item => {
      const Icon = buildIcon( item.icon )

      return <li
        key={ item.identity }
        title={ item.label }
      >
        <NavLink
          className={ ( { isActive } ) => isActive ? 'active': '' }
          to={ item.path }
        >
          <span className="icon">
            <Icon />
          </span>
          <span>{ item.label }</span>
        </NavLink>
      </li>
    } )

  return (
    <aside className={ isMinified ? 'minified' : '' }>
      <header>
        <div
          className={ isMinified ? 'icon' : 'logo' }
        ></div>
      </header>

      <section>
        <nav>
          <ul>
            { menu }
          </ul>
        </nav>
      </section>

      <footer className={ isMinified ? 'hs' : 'vs' }>
        <button
          title="cerrar sesi&oacute;n"
          onClick={ logout }
        >
          <FiPower />
        </button>

        <button
          title={ isMinified ? "expandir" : "ocultar" }
          onClick={ () => setIsMinified( ! isMinified ) }
        >
          { isMinified ? <FiChevronsRight /> : <FiChevronsLeft /> }
        </button>
      </footer>
    </aside>
  )
}

export default withProfile( withCatalog( Aside ) )
