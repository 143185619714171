import Fetch from '../../../components/Fetch'
import Line from '../../../components/Line'

export default function Area( props ) {
  const { card } = props

  return (
    <div className="vs card graph">
      <Fetch
        type="area"
        endpoint={ card.endpoint }
        params={ card.params }
        useFilter={ card.useFilter }
      >
        { ( data ) => {
          return <Line
            data={ data.timeline }
            lines={ data.lines }
          />
        } }
      </Fetch>
    </div>
  )
}
