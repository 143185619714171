import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  AiOutlineEnter
} from 'react-icons/ai'

export default function Find( props ) {
  const [ identity, setIdentity ] = useState()
  const navigate = useNavigate()

  function updateOwnerId( event ) {
    setIdentity( event.target.value )
  }

  function send( event ) {
    if ( event.keyCode !== 13 ) return

    navigate( `/permissions/${ identity }` )
  }

  return (
    <main>
      <section className="vs middle">
        <div
          className="hs w-300x"
        >
          <div className="vs composed-input transparent">
            <input
              value={ identity }
              onChange={ updateOwnerId }
              onKeyDown={ send }
              className="text giant w-300x centered transparent"
              placeholder="id titular"
            />

            <div className="text giant o-50p">
              <AiOutlineEnter />
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
