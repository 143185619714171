import { Component } from 'react'

class Timeout extends Component {
  interval
  left
  state = {
    show: true
  }

  componentDidMount() {
    this.left = this.props.timeout || 7

    this.interval = setInterval( () => {
      if ( this.left === 0 ) {
        clearInterval( this.interval )
        this.setState( { show: false } )
      }

      this.left--
    }, 1000 )
  }

  render() {
    const { onClick = () => {} } = this.props

    return this.state.show ? (
      <div onClick={ onClick }>
        { this.props.children }
      </div>
    ) : null
  }
}

export default Timeout
