import { faker } from '@faker-js/faker'

function buildItem() {
  const multiplier = faker.random.number( 10 )

  return {
    verificado: faker.random.number( 10000 ) * multiplier,
    notificado: faker.random.number( 10000 ) * multiplier,
    eliminado: faker.random.number( 10000 ) * multiplier,
    rechazado: faker.random.number( 10000 ) * multiplier,
    name: faker.random.alpha( 7 )
  }
}

const line = [
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
  buildItem(),
]

export default line
