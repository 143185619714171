import React, { useState, useRef, useMemo } from 'react'

import { FiCheck } from 'react-icons/fi'
import { AiOutlineClear } from 'react-icons/ai'

function isObject( variable ) {
  return typeof variable === 'object' && !Array.isArray( variable ) && variable !== null
}

export default function Select( props ) {
  const {
    className = '',
    id,
    name,
    multiple = true,
    value,
    onChange,
    placeholder
  } = props
  const [ isOpen, setIsOpen ] = useState( false )
  const [ selectedList, setSelectedList ] = useState( [] )
  const optionsContainer = useRef()

  useMemo( () => {
    if ( ! value )
      return

    let aux = [ value ]
    if ( value instanceof Array || value instanceof Set )
      aux = Array.from( value )

    setSelectedList( aux )
  }, [ value ] )

  function updateSelected( event ) {
    const { value, checked } = event.target
    const index = selectedList.indexOf( value )

    if ( index === -1 && checked )
      setSelectedList( [ ...selectedList, value ] )

    if ( ! checked && index !== -1 ) {
      const copyList = [ ...selectedList ]
      copyList.splice( index, 1 )
      setSelectedList( [ ...copyList ] )
    }
  }

  const options = props.options
    .map( op => isObject( op ) ? op : { label: op, value: op } )
    .map( op => {
      let checked = false

      if ( value )
        checked = value.toString() === op.value.toString()

      if ( value instanceof Set )
        checked = value.has( op.value )

      return (
        <label key={ op.value }>
          <input
            id={ id }
            name={ name }
            type={ multiple ? 'checkbox' : 'radio' }
            value={ op.value }
            onChange={ e => onChange( e, toggle ) }
            checked={ checked }
            onClick={ updateSelected }
          />
          { op.label }
        </label>
      )
    } )

  function clear( event ) {
    const checkboxes = Array.from( optionsContainer.current.querySelectorAll( 'input[type=checkbox]' ) )

    checkboxes
      .filter( input => input.checked )
      .forEach( input => input.click() )

    setIsOpen( ! isOpen )
  }

  function leave( event ) {
    if ( isOpen )
      setIsOpen( ! isOpen )
  }

  function toggle() {
    setIsOpen( ! isOpen )
  }

  return (
    <div
      className={ `${ className } select` }
      onMouseLeave={ leave }
    >
      <span className="placeholder" onClick={ () => setIsOpen( ! isOpen ) }>
        { selectedList.length === 0 ? placeholder || '' : selectedList.join( ', ' ) }
      </span>

      <div className={ isOpen ? 'options open' : 'options' }>
        <header>
          <h1>
            { placeholder }
          </h1>

          { multiple ? (
            <div className="actions">
              <button type="button" onClick={ () => setIsOpen( ! isOpen ) }>
                <FiCheck />
              </button>

              <button type="button" onClick={ clear }>
                <AiOutlineClear />
              </button>
            </div>
          ) : null }
        </header>

        <section ref={ optionsContainer }>
          { options }
        </section>
      </div>
    </div>
  )
}
