import React, { createContext, useState, useEffect } from 'react'
import http from '../http'

const SkinContext = createContext()

function Skin( props ) {
  const [ skin, setSkin ] = useState( {} )
  const { endpoint } = props

  useEffect( () => {
    if ( ! endpoint ) return

    if ( skin.theme ) return

    http
      .get( endpoint )
      .then( response => {
        setSkin( response.data )
        dressUp( response.data.theme )
      } )
  } )

  function dressUp( theme ) {
    Object.keys( theme )
      .forEach( property => {
        document.documentElement.style.setProperty( `--${ property }`, theme[ property ] )
      } )
  }

  // prevent render
  if ( ! skin.theme ) return null

  return (
    <SkinContext.Provider
      value={ {
        ...skin
      } }
    >
      { props.children }
    </SkinContext.Provider>
  )
}

const withSkin = ( ComponentAlias ) => {
  return props => (
    <SkinContext.Consumer>
      { context => <ComponentAlias { ...props } withSkin={ context } /> }
    </SkinContext.Consumer>
  )
}

export {
  withSkin,
  SkinContext
}

export default Skin
