import React, { useState } from 'react'
import buildIcon from '../helpers/buildIcon'
import modals from '../variables/modal.collection'

export default function ButtonToModal( props ) {
  const { button } = props
  const [ isOpen, setIsOpen ] = useState( false )

  const Icon = buildIcon( button.icon )

  const Modal = modals[ button.modal ]

  function toggle() {
    setIsOpen( ! isOpen )
  }

  return (
    <>
      <button
        title={ button.title }
        onClick={ toggle }
      >
        <Icon />
      </button>

      { isOpen ?
        <Modal
          { ...props.button }
          close={ toggle }
        />
      : null }
    </>
  )
}
